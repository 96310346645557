/*TODO:
* - Unify colors (search for hash)
*/
/* I used this to stick the footer to the bottom of hte page, I don't think it's needed anymore
html {
  position: relative;
  min-height: 100%;
}*/


/* 1. GLOBAL
----------------------------------------------------------------------------------------*/

body
{
  font-family: $font-family-serif;
  font-feature-settings: "onum";
  background-color: $paper;
  color: $black;
}
@media (prefers-color-scheme: dark) {
	body{
		background-color: $paper-dark;
		color: $body-color-dark;
	}
}

/*Makes images responsive*/
img {
	max-width: 100%;
	height: auto;
}

ul { list-style-type: square; margin-left: 1rem; }
ol { list-style-type: decimal; margin-left: 2rem; }
nav ul, nav ol { list-style: none; margin: 0; padding: 0;}

b, strong { font-weight: 600; }
i, em { font-style: italic; }
small { font-size: 80%; }
sup{font-size:80%; vertical-align:super;line-height: 0;} 

sup {
  top: 0em;
}

/* 2. LINKS
----------------------------------------------------------------------------------------*/
a, a:visited { outline: none; color: $dd-blue; text-decoration:none;}  
a:hover { outline: none; text-decoration: underline; color: $dd-purple;}  
a:active, a:focus { outline: none; }
@media (prefers-color-scheme: dark) {
	a, a:visited {
		color: $dd-blue-light;
	}
	a:hover {
		color: $dd-purple-light;
	}
}


/* 3. MENU
----------------------------------------------------------------------------------------*/

.navbar
{
  padding-top: 3em;
  width: 100%;
}
@include media-breakpoint-down(lg) {
	.navbar
	{
	  padding-top: 1em;
	}
}

.navbar #header-logo img
{
  height: 4rem;
  width: 4rem;
}

.navbar .container-fluid{
	margin: 0!important;
	padding: 0 !important;
}

.navbar .navwrapper
{
  width: 100%;
  position: relative;
}

.navbar .navbar-collapse
{
  position: absolute;
  bottom: 0;
  right: 0;
}

.navbar .navbar-brand
{
  padding: 0;
  margin: 0;
}

.navbar .nav-link
{
	@include font-serif-bold;
	padding: 0px;
	text-decoration: none;
	line-height: 1.3em;
	font-size: 1.25em;
	margin: 0em 0px 0 1em;
	color: $dd-blue!important;
}
@include media-breakpoint-down(lg) {
	.navbar .nav-link
	{
		padding: 5px 0 5px 0;
		margin-top: 0.3em;
		margin-bottom: 0.3em;
	}
}
@media (prefers-color-scheme: dark) {
	.navbar .nav-link
	{
		color: $body-color-dark!important;
	}
}

.navbar a.nav-link:hover {
    color: $dd-purple!important;
	text-decoration: underline;
}
@media (prefers-color-scheme: dark) {
	.navbar a.nav-link:hover {
		color: $dd-purple-light!important;
	}
}

.navbar a.nav-link
{
	padding: 0!important;
}

.navbar .selected a.nav-link {
    color: $dd-purple;
}
@media (prefers-color-scheme: dark) {
	.navbar .selected a.nav-link {
		color: $dd-purple-light;
	}
}

.navbar .selected a{
    color: $dd-blue !important;
    text-decoration: underline;
}
@media (prefers-color-scheme: dark) {
	.navbar .selected a{
		color: $body-color-dark!important;
	}
}

.navbar .navbar-toggler
{
	position: absolute;
	right: 0px;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;

	border: 2px solid $dd-blue;
	height: 4rem;
	width: 4rem;
}
.navbar .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(47, 127, 168, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")!important;
}

.navbar .navbar-toggler:focus,
.navbar .navbar-toggler:active,
.navbar .navbar-toggler-icon:focus {
    outline: none!important;
    box-shadow: none!important;
	border: 2px solid $dd-purple;
}

@media (prefers-color-scheme: dark) {
	.navbar .navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(130, 178, 203, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")!important;
	}
	.navbar .navbar-toggler {
		border-color: $dd-blue-light!important;
	} 
}

@include media-breakpoint-down(lg) {
	.navbar .navbar-collapse
	{
	  position: relative;
	  text-align: right;
	}
}



/* 4. PAGES AND POSTS
----------------------------------------------------------------------------------------*/

/* 4.0 OVERALL
----------------------------------------------------------------------------------------*/

#main {
	clear: both;
	padding-top: 5em;
	font-size: 1.25em;
	font-weight: 400;
	padding-bottom: 2em;
 	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;
}
@include media-breakpoint-down(lg) {
	#main
	{
		padding-top: 2em;
		font-size: 1.1em;
	}
}

#main .entry-content
{
	position: relative;
	padding-top: 1rem;
}

/* 4.1 HEADER
----------------------------------------------------------------------------------------*/
#main h1.entry-title
{
	@include font-sans-bold;
	color: $black;
	font-size: 2em;
	margin: 0;
	padding: 0.1em 0rem 0.2em 0em;
}
@include media-breakpoint-down(lg) {
	#main h1,
	#main h1.entry-title {
		font-size: 1.8em;
	}
}
@media (prefers-color-scheme: dark) {
	#main h1,
	#main h1.entry-title {
		color: $body-color-dark;
	}
}

#main p.entry-slug
{
	@include font-serif-regular;
	color: $body-color-muted-dark;
	margin-bottom: 3rem;
	font-size: 1.1rem;
}
@media (prefers-color-scheme: dark) {
	#main p.entry-slug
	{
		color: $body-color-muted-dark;
	}
}

#main .banner figure {
	margin: 0;
	padding: 0;
}

#main .banner {
	line-height: 0;
	margin: 0px;
	margin-bottom: 2rem;
}


/* 4.2 TEXT
----------------------------------------------------------------------------------------*/

/* 4.2.1 HEADINGS
----------------------------------------------------------------------------------------*/

#main .entry-content h1 {
	@include font-sans-bold;
	color: $black;
	font-size: 2em;
	padding: 0.1em 0rem 0.2em 0em;
	margin: 1.3em 0 0.1em 0;
}

#main .entry-content h1 a, #main .entry-content h2 a {
	text-decoration: none;
}

#main .entry-content h2 {
	@include font-sans-bold;
	color: $black;
	padding: 0em 1em 0.1em 0em;
	font-size: 1.3em;
	margin: 2em 0 0.5em 0;
}

#main .entry-content h3, #main .entry-content h4
{
	@include font-sans-medium;
	padding: 0em 1em 0.1em 0em;
	font-size: 1.3em;
	margin: 2em 0 0.5em 0;
	width: 100%;
	color: $black;
}
@media (prefers-color-scheme: dark) {
	#main .entry-content h1,
	#main .entry-content h2,
	#main .entry-content h3,
	#main .entry-content h4
	{
		color: $body-color-dark;
	}
}

/* 4.2.2 DROP CAPS
----------------------------------------------------------------------------------------*/

.dropcap
{
	@include font-sans-medium;
	color: $black;
	float: left;
	font-size: 3.4em;
	line-height: 0.75;
	margin-right: 0.1em;
	margin-top: 0.06em;
}
@include media-breakpoint-down(lg) {
	.dropcap
	{
		margin-top: 0.07em;
	}
}
@media (prefers-color-scheme: dark) {
	.dropcap
	{
		color: $body-color-dark;
	}
}

/* 4.2.3 PRE/POST TEXT + QUOTES
----------------------------------------------------------------------------------------*/
.pre, .postscript
{
	background-color: $paper-highlight;
	padding: 0.5rem 1rem;
	font-style: italic;
	border-left: 5px solid $dd-purple-light;
	margin-bottom: 1rem;
}
@media (prefers-color-scheme: dark) {
	.pre, .postscript
	{
		background-color: $paper-highlight-dark;
		border-left: 5px solid $dd-purple;
	}
}

.pre p:last-of-type, .postscript p:last-of-type {
	/* Remove margin from last paragraph */
	margin-bottom: 0;
}

.pre em, .pre i, .pre strong,
.postscript em, .postscript i, .postscript strong,
blockquote em, blockquote i, blockquote strong
{
	font-style: normal;
}

blockquote
{
	padding: 0.5rem 1rem;
	font-style: italic;
	border-left: 5px solid $dd-blue-light;
}
@media (prefers-color-scheme: dark) {
	blockquote
	{
		border-left: 5px solid $dd-blue;
	}
}


blockquote p
{
	padding: 0px;
	margin: 0;
}

/* 4.2.4 DOWNLOADS
----------------------------------------------------------------------------------------*/
.download p a
{
	display: block;
	background-image:url('/src/img/icon/download.png');
	background-repeat: no-repeat;
	background-position: 1rem center;
	background-color: $green;
	color: $paper;
	padding: 0.5rem 0 0.5rem 4rem;
}

.download p a:link
{
	background-image:url('/src/img/icon/download_green.png');
}

.download p a:hover
{
	background-image:url('/src/img/icon/download.png');
	background-color: $dd-blue;
	color: $paper;
	text-decoration: none;
}

/* 4.2.5 TEXT
----------------------------------------------------------------------------------------*/

#main .entry-content hr
{
	border-color: $black;
	border-width: 2px;
	width: 20%;
	margin: auto;
	margin-top: 2em;
	margin-bottom: 2em;
	opacity: 1;
}
@media (prefers-color-scheme: dark) {
	#main .entry-content hr
	{
		border-color: $body-color-dark;
	}
}

strong
{
	font-weight: 600;
}


/* 4.2.6 CONTENTS
----------------------------------------------------------------------------------------*/

.contents {
	@include font-sans-regular;
	position: absolute;
	right: -16rem;
	top: 0;
	width: 15rem;
	border: none;
	background: none;
	border-top: 1px solid $black;
	padding-left: 1rem;
	padding-top: 0.5rem;
	font-size: 1.05rem;
	line-height: 1.3em;
	max-width: 100%;
}
@media (prefers-color-scheme: dark) {
	.contents {
		border-top: 1px solid $body-color-dark;
	}
}

.contents li
{
	list-style:none;
}
.contents ul
{
	margin: 0;
	padding: 0!important;
}
.contents ul li
{
	list-style:square;
}

@include media-breakpoint-down(lg) {
	.contents
	{
		position: relative;
		right: 0;
		width: auto;
		padding-left: 0;
		margin: 0;
		border: none;
		padding-bottom: 1rem;
	}
}


/* 4.2.7 TABLES
----------------------------------------------------------------------------------------*/
#main table {
    overflow-x: auto;
	margin: 0 0 1rem 0;
	background-color: $paper-highlight;
}
@media (prefers-color-scheme: dark) {
	#main table{
		color: $body-color-dark;
	}
}

#main table td {
	vertical-align:top;
  }

#main table {
    border-collapse: collapse;
    width: 100%
}

#main table thead {
    border-bottom: 3px solid $border;
}

#main table tfoot {
    border-top: 3px solid $border;
}
@media (prefers-color-scheme: dark) {
	#main table thead {
		border-bottom: 3px solid $border-dark;
	}
	#main table tfoot {
		border-top: 3px solid $border-dark;
	}
}


#main table td, #main table th {
    border: 1px solid $border;
    padding: .5em
}
@media (prefers-color-scheme: dark) {
	#main table td, #main table th {
		border-color: $border-dark;
		background-color: $paper-highlight-dark;
	}
}

#main table.has-fixed-layout {
    table-layout: fixed;
    width: 100%;
}

#main table.has-fixed-layout td, table.has-fixed-layout th {
    word-break: break-word;
}

/* 4.2.8 Footnotes
----------------------------------------------------------------------------------------*/
.entry-content ul, .entry-content ol {
	padding-left: 1.2rem;
	margin-left: 2rem;
}

p:has(.footnote_ref) {
	text-indent: -3.2rem;
	margin-left: 3.2rem;
}
.footnote_ref {
	width: 3.2rem;
	display: inline-block;
	text-indent: 0;
	text-align: right;
	padding-right: 0.5rem;
}

.bibliography
{
	text-indent: -3.2rem;
	margin-left: 3.2rem;
}

@include media-breakpoint-down(lg) {
	.entry-content ul, .entry-content ol {
		padding-left: 1rem;
		margin-left: 1rem;
	  }
	
	p:has(.footnote_ref) {
		text-indent: -2rem;
		margin-left: 2rem;
	}
	.footnote_ref {
		width: 2rem;
	}
	.bibliography
	{
		text-indent: -2rem;
		margin-left: 2rem;
	}
}


/* 4.2.9 SYNTAX HIGHLIGHTER
----------------------------------------------------------------------------------------*/
pre code
{
	font-family: $font-family-mono;
	margin: 0;
	display: block;
	white-space: pre-wrap;
	border-left: 2px solid $dd-blue;
	padding-left: 1rem;
}






/* 5 IMAGES AND VIDEOS
----------------------------------------------------------------------------------------*/

/* 5.1 IMAGES
----------------------------------------------------------------------------------------*/
figure
{
	width: 100%!important; /*overrides wordpress weirdness*/
	position: relative;
	padding-bottom: 1.618em;
}
@include media-breakpoint-down(lg) {	
	figure
	{
		padding-bottom: 0;
	}
}

.entry-content figure
{
	margin-top: 3em;
	margin-bottom: 3em;
    padding: 0;
}

.entry-content figure img
{
	background-color: $paper;
}

.entry-content h1 + figure,
.entry-content h2 + figure,
.entry-content h3 + figure,
.entry-content h4 + figure,
.entry-content h5 + figure,
.entry-content h6 + figure {
	/*If figure is the first element after a heading, don't add margin*/
	margin-top: 1em!important;
}

figcaption
{
	@include font-sans-regular;
	position: absolute;
	right: -11rem;
	top: 0;
	width: 10rem;
	border: none;
	background: none;
	border-top: 1px solid $black;
	padding-left: 1rem;
	padding-top: 0.5rem;
	margin-top: 0!important;
	font-size: 1.05rem;
	line-height: 1.3em;
	max-width: 100%;
	text-align: left!important;
	font-size: 1.05rem!important;
	color: $black!important;
}
@include media-breakpoint-down(lg) {	
	figcaption
	{
		position: relative;
		right: 0;
		width: auto;
		padding-left: 0;
		border: none;
		font-style: italic;
	}
}
@media (prefers-color-scheme: dark) {
	figcaption {
		color: $body-color-dark!important;
		border-top: 1px solid $body-color-dark;
	}
}

.img-border
{
	border: 1px solid $border;
	position: relative;
	left: -1px;
	-moz-box-shadow: 0px 1px 5px $shadow;
	-webkit-box-shadow: 0px 1px 5px $shadow;
	box-shadow: 0px 1px 5px $shadow;
}
@media (prefers-color-scheme: dark) {
	.img-border
	{
		border: none;
		box-shadow: none;
	}
}

@include media-breakpoint-down(lg) {	
	.img-border
	{
		border: none !important;
		position: relative;
		left: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}


/* 5.1 VIDEOS
----------------------------------------------------------------------------------------*/

.eleventy-plugin-youtube-embed, .eleventy-plugin-vimeo-embed{
	margin-bottom: 1rem;
	background: $paper-dark;
}




/* 6 END MATTER
----------------------------------------------------------------------------------------*/
.jumbotron
{
	margin: 0;
	padding: 0;
	border: none!important;
	background-color: unset;
}

.jumbotron h2 {
	@include font-sans-bold;
	color: $black;
	padding: 0em 1em 0.1em 0em;
	font-size: 1.3em;
	margin: 2em 0 0.5em 0;
}
@media (prefers-color-scheme: dark) {
	.jumbotron h2 {
		color: $body-color-dark;
	}
}

/* 6.1 SHARING
----------------------------------------------------------------------------------------*/
.jumbotron.shares
{
	margin: 0;
	padding: 0 0 2em 0;
	font-size: 1.2em;
	font-weight: 400;
}

.shares h2
{
	border-bottom: 2px solid $black;
}
@media (prefers-color-scheme: dark) {
	.shares h2 {
		border-color: $body-color-dark;
	}
}

#share ul{
	list-style:none;
	padding: 0;
	margin: 0;
}
@include media-breakpoint-down(lg) {	
	.shares 
	{
		font-weight: 400;
		font-size: 1.1em;
	}
}


/* 6.2 COMMENTS
----------------------------------------------------------------------------------------*/

.jumbotron.comments
{
  background-color: $paper-highlight;
  border-top: 1px solid $border!important;
  border-bottom: 1px solid $border!important;
  border-radius: 0;
}
@media (prefers-color-scheme: dark) {
	.jumbotron.comments
	{
		background-color: $paper-highlight-dark;
		border-top: 1px solid $border-dark!important;
		border-bottom: 1px solid $border-dark!important;
	}
}

.comments-closed {
    font-weight: 400;
    padding-left: 0.5em;
}

@include media-breakpoint-down(lg) {	
	.comments 
	{
		font-weight: 400;
		font-size: 1.1em;
	}
}

.comments h2
{
	border-bottom: 2px solid $black;
}


.comments
{
	font-size: 1.2em;
	font-weight: 400;

	/*Stop long URLs breaking the page: from https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/*/
	overflow-wrap: break-word;
	word-wrap: break-word;
}

.comment-list
{
	padding: 0;
}

.comment-list li 
{
	padding: 0;
	margin: 0;
	list-style: none;
	clear: both;
}

.comment-list ul
{
	margin: 0 0 0 0;
	padding: 0;
}

.comment-list li
{
	border-left: 2px solid $black!important;
	padding-left: 1em;
}
@media (prefers-color-scheme: dark) {
	.comment-list li
	{
		border-left: 2px solid $body-color-dark!important;
		padding-left: 1em;
	}
}

.comment-list > li
{
	border: none!important;
	padding-left: 0;

}

ul.comment-list
{
	margin: 0 0 2rem 0;
}

li.depth-1
{
	padding-bottom: 3rem;
}

.comment-body
{
	padding-bottom: 1rem;
}

.comment-list h2
{
	color: $black;
	font-size: 1em;
    overflow: hidden;
    font-weight: 700;
    margin-top: 0;
    border: none;
    padding-right: 0
}

.comment-list h2 a
{
	color: $black;
}
@media (prefers-color-scheme: dark) {
	.comment-list h2, .comment-list h2 a
	{
		color: $body-color-dark;
	}
}

.comment-list .date {
	float: right;
	font-weight: 400;
	font-size: 0.8em;
}

.comment-list .date a {
	color: #666;
}

.comment-list p
{
	padding: 0 1rem 0 0;
	margin-bottom: 1em;
}

.comment .entry-content
{
	padding-top: 0rem;
}

.comment-reply-title {
	@include font-sans-bold;
	color: $black;
	padding: 0em 1em 0.1em 1rem;
	font-size: 1.3em;
	margin: 2em 0 0.5em 0;
}
@media (prefers-color-scheme: dark) {
	.comment-reply-title {
		color: $body-color-dark;
	}
}


@media (prefers-color-scheme: dark) {
	.comments h2
	{
		border-bottom: 2px solid $body-color-dark;
	}
}

/* 6.3 FOOTER
----------------------------------------------------------------------------------------*/
.jumbotron.footer{
	width: 100%;
	height: 4rem; /* Set the fixed height of the footer here */
	line-height: 60px; /* Vertically center the text there */
	background-color: $paper;
}
@include media-breakpoint-down(lg) {
	.jumbotron.footer
	{
		line-height: 1.5em;
		height: 5rem;
	}
}
@media (prefers-color-scheme: dark) {
	.jumbotron.footer{
		background: $paper-dark;
		/*border-top: 1px solid $dd-blue-light!important;*/
	}
}
  
footer
{
	@include font-sans-regular;
	clear: both;
	font-size: 1rem;
	padding: 0em 0 2rem 0em ;
	color: #666;
}
@media (prefers-color-scheme: dark) {
	footer
	{
		color: $body-color-dark;
	}
}

footer .follow
{
	float: right;
}
@include media-breakpoint-down(lg) {
	footer .follow
	{
		float: left;
		clear: both;
	}
}

footer p
{
	margin: 0;
}

footer .name
{
	float: left;
	padding-right: 2em;
}

@include media-breakpoint-down(lg) {
	.jumbotron.footer p
	{
		padding-top: 1em;
	}
}



/* 9 PAGE SPECIFIC
----------------------------------------------------------------------------------------*/

/* 9.1 Writing page
----------------------------------------------------------------------------------------*/
.synopsis
{
	font-weight: 400;
	color: $body-color-muted;
}
@media (prefers-color-scheme: dark) {
	.synopsis
	{
		color: $body-color-muted-dark;
	}
}



.synopsis a
{
	font-weight: 400;
}
@include media-breakpoint-down(lg) {
	.synopsis a
	{
		font-weight: 600;
	}
}

.main-synopsis h2{
	font-weight: 500!important;
	font-size: 1em!important;
	margin-bottom: 0!important;
}
#main.main-synopsis
{
	padding-top: 0em;
}

/* 9.2 ABOUT PAGE
----------------------------------------------------------------------------------------*/

figure.portrait {
    margin: 0;
}

div.row.about {
	padding-bottom: 1em;
}

div.row.about p{
	padding-top: 1em;
}
@include media-breakpoint-down(lg) {	
	div.row.about p{
		padding-top: 0em;
	}
}

@include media-breakpoint-down(lg) {	
	.about .col-2{
		padding-right: 0;
	}
}

/* 9.3 PUBLICATIONS PAGE
----------------------------------------------------------------------------------------*/

.publications table
{
	margin-top: 1em;
}

.publications #main h3
{
	margin: 1em 0 0.2em 0;
	padding: 0;
}
@include media-breakpoint-down(lg) {	
	.publications td,
	.publications th
	{
		padding-left: 0.4em;
		padding-right: 0.4em;
	}
}

/* 9.4 TALKS PAGE
----------------------------------------------------------------------------------------*/
@include media-breakpoint-down(lg) {	
	.talks td,
	.talks th
	{
		padding-left: 0.4em;
		padding-right: 0.4em;
	}
}


/* 9.5 INDEX PAGE
----------------------------------------------------------------------------------------*/
.writing .link-collection {
	padding-top: 1.25rem;
	padding-bottom: 0.25rem;
	margin-bottom: 2.5rem;
}
  
.writing .link-collection .list-group-item {
	background-color: $paper-highlight;
	border-color: $border;
}
@media (prefers-color-scheme: dark) {
	.writing .link-collection .list-group-item {
		background-color: $paper-highlight-dark;
		border-color: $border-dark;
	}
}

.writing .link-collection .list-group-item {
	padding-top: 0.7em;
	padding-bottom: 0.7rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.writing .link-collection .source{
	font-style: italic;
	color: $body-color-muted;
}
@media (prefers-color-scheme: dark) {
	.writing .link-collection .source{
		color: $body-color-muted-dark;
	}
}



.writing a.list-group-item {
	color: $body-color;
}
@media (prefers-color-scheme: dark) {
	.writing a.list-group-item {
		color: $body-color-dark;
	}
}

.writing a:hover.list-group-item {
	background: $border;
}
@media (prefers-color-scheme: dark) {
	.writing a:hover.list-group-item {
		background: $border-dark;
	}
}
  
.writing a:hover.list-group-item .title {
	color: $dd-purple;
	text-decoration: underline;
}
@media (prefers-color-scheme: dark) {
	.writing a:hover.list-group-item .title {
		color: $body-color-dark;
	}
}

.writing .title{
	@include font-sans-medium;
}

.writing .list-group.purple
{
  border-left: 5px solid $dd-purple-light;
}
@media (prefers-color-scheme: dark) {
	.writing .list-group.purple {
		border-left-color: $dd-purple;
	}
}

.writing .list-group.blue
{
  border-left: 5px solid $dd-blue-light;
}
@media (prefers-color-scheme: dark) {
	.writing .list-group.blue {
		border-left-color: $dd-blue;
	}
}

.writing .list-group.green
{
  border-left: 5px solid $dd-aqua-light;
}
@media (prefers-color-scheme: dark) {
	.writing .list-group.green {
		border-left-color: $dd-aqua;
	}
}

.writing .list-group.red
{
  border-left: 5px solid $dd-red-light;
}
@media (prefers-color-scheme: dark) {
	.writing .list-group.red {
		border-left-color: $dd-red;
	}
}

.writing .list-group{
	border-right: 1px solid $border;
}
.writing .list-group-item:first-child {
	border-top: 1px solid $border;
}
.writing .list-group-item:last-child {
	border-bottom: 1px solid $border!important;
}

@media (prefers-color-scheme: dark) {
	.writing .list-group{
		border-right: 1px solid $border-dark;
	}
	.writing .list-group-item:first-child {
		border-top: 1px solid $border-dark;
	}
	.writing .list-group-item:last-child {
		border-bottom: 1px solid $border-dark!important;
	}
}


.writing p, .writing-publications p 
{
  margin-bottom: 2rem;
}

.writing .see-all{
	@include font-sans-regular;
	padding-left: 1.25rem;
	padding-top: 0.5rem;
	display: block;
	border-left: 5px solid $paper; /* To keep spacing with border on list-group */
}
@media (prefers-color-scheme: dark) {
	.writing .see-all {
		border-left-color: $paper-dark;
	}
}
  
.link-collection h2 {
	padding: 0;
	padding-left: 1.25rem!important;
	margin: 0!important;
	font-size: 1.4rem!important;
	padding-bottom: 0.5rem!important;
	border-left: 5px solid $paper; /* To keep spacing with border on list-group */
}
@media (prefers-color-scheme: dark) {
	.link-collection h2 {
		border-left-color: $paper-dark;
	}
}

  